var keyMirror = require('keymirror');

module.exports = keyMirror({
    LOGO_IMAGES: null,
    EDIT_PROFILE_NAME: null,
    WEEKLY_SYNC_AUTO: null,
    GET_WEEKLY_SYNC_AUTO: null,
    UPLOAD_FIRST_PARTY_CSV:null,
    PROFILE_LEVEL_LOGO_UPLOAD:null,
    DATA_STUDIO_CONNECTOR:null,
    GET_SCRAPPER_DATA: null,
    UPDATE_SCRAPPER_DATA: null,
    FETCH_AUDIT_DATA: null,
    AUDIT_DATA_FETCH_STATUS: null,
});

