module.exports = {
    sourceList: {
        "google": "Google",
        "facebook": "Facebook",
        "tripadvisor": "Tripadvisor",
        "yelp": "Yelp",
        "booking": "Booking",
        "cellarpass": "Cellarpass",
        "lawyers": "Lawyers",
        "ratemds": "Ratemds",
        "vitals": "Vitals",
        "healthgrades": "Healthgrades",
        "productreview": "Productreview",
        "apartments": "Apartments",
        "zomato": "Zomato",
        "zocdoc": "Zocdoc",
        "trustpilot": "Trustpilot",
        "glassdoor": "Glassdoor",
        "hostellingscotland": "Hostelling Scotland",
        "hostelworld":"Hostel World",
        "expedia":"Expedia",
        "indeed":"Indeed",
        "opentable":"OpenTable",
        "zillow":"Zillow",
        "hotels":"Hotels.com",
        "foursquare":"Foursquare",
        "reviewshakegmb":"GBP Public",
        "appstore":"App Store Index",
        "googleplaystore":"Google Play Index",
        "cars":"Cars.com",
        "cargurus":"CarGurus",
        "dealerrater":"Dealerrater",
        "edmunds":"Edmunds",
        "talabat":"Talabat",
        "reserveout":"Reserveout",
        "homeadvisor":"HomeAdvisor",
        "trustradius":"TrustRadius",
        "capterra":"Capterra",
        "gtwo":"G2 Crowd",
        "airbnb":"Airbnb",
        "reviewshaketrustpilot":"Trustpilot Index",
        "reviewshakefacebook":"Facebook Index",
        "betterbusinessbureau":"BBB",
        "reviewshakebooking": "Booking Index",
        "playstore":"Google Play",
        "appleappstore":"App Store",
        // "applemaps": "Apple Places",
        "applebusinessconnect": "Apple Maps",
        "trustedshops":"TrustedShops",
        "cardealer":"Car Dealer Reviews",
        'googlepublic':'Google Public',
        'angieslist':'Angies List',
        'avvo':'Avvo',
        'houzz':'Houzz',
        'homestars':'Home Stars',
        'highya': 'Highya',
        'rent':'Rent',
        'parent':'Location',
        'ubereats':'Uber Eats',
        'doordash':'Doordash',
        'grubhub':'Grubhub'
    },
    sourceListings:{
        'listing':'Listing',
        'department':'Department',
        'core':'Core'
    }
}