module.exports = { 
    SOURCE_COLOR_MAP: {
        "google": "#4885ED",
        "facebook": "#3B5998",
        "tripadvisor": "#00AF87",
        "yelp": "#F15C4F",
        "booking": "#003580",
        "cellarpass": "#92A2A8",
        "lawyers": "#00B0F2",
        "ratemds": "#529A9E",
        "vitals": "#673Ab7",
        "healthgrades": "#0202EA",
        "productreview": "#ef6023",
        "apartments": "#88DA64",
        "zomato": "#CB202D",
        "zocdoc": "#C382FF",
        "trustpilot": "#F37324",
        "glassdoor": "#51ab42",
        "hostellingscotland": "#005CB9",
        "hostelworld": "#ee734c",
        "expedia": "#FFC717",
        "indeed": "#2162f4",
        "opentable": "#DA3743",
        "zillow": "#1277E1",
        "hotels": "#EC2C45",
        "foursquare": "#F94877",
        "reviewshakegmb": "#ACB9CF",
        "googleplaystore": "#227491",
        "appstore": "#555555",
        "cars": "#532380",
        "cargurus": "#ED3341",
        "edmunds": "#565658",
        "dealerrater": "#FA7412",
        "talabat": "#FF5A00",
        "reserveout": "#EB1C22",
        "homeadvisor": "#F8901F",
        "trustradius": "#116BF2",
        "capterra": "#68C4E9",
        "gtwo": "#EF492D",
        "airbnb": "#EB5A62",
        'reviewshaketrustpilot': "#67f2ca",
        "reviewshakefacebook":"#3B5998",
        "betterbusinessbureau":"#006187",
        "reviewshakebooking":"#003580",
        "playstore": "#3BCCFF",
        "appleappstore":"#000000",
        // "applemaps":"#2ec219",
        "applebusinessconnect":"#2ec219",
        "trustedshops":"#ffdd39",
        "cardealer":"#7665ea",
        "ubereats":"#01C268",
        "doordash":"#EB1700",
        "grubhub":"#FF8000",
    },
    sourceImages: {
        "yelp": "/images/yelp.svg",
        "booking": "/images/booking.png",
        "reviewshakebooking": "/images/bookingIndex.png",
        "cellarpass": "/images/cellarpassicon.png",
        "google": "/images/googless.png",
        "facebook": "/images/facebook-reviews.png",
        "tripadvisor": "/images/TripAdvisor1.png",
        "lawyers": "/images/lawyers-icon.png",
        "ratemds": "/images/ratemds-icon.png",
        "vitals": "/images/vitals-icon.png",
        "healthgrades": "/images/healthgrades-icon.png",
        "productreview": "/images/product.png",
        "apartments": "/images/apartment.png",
        "zomato": "/images/zomato.png",
        "glassdoor": "/images/glassdoor-icon.png",
        "hostellingscotland": "/images/HostellingScotland.png",
        "hostelworld": "/images/hostelworld-icon.png",
        "expedia": "/images/expedia-icon.png",
        "indeed": "/images/indeed-icon.png",
        "opentable": "/images/opentable-icon.png",
        "zillow": "/images/zillow-icon.png",
        "hotels": "/images/hotels-icon.png",
        "foursquare": "/images/fourSquare-icon.png",
        "reviewshakegmb": "/images/GMB-Index-icon.png",
        "googleplaystore": "/images/googleplaystore-icon.png",
        "appstore": "/images/appstoreindex-icon.png",
        "zocdoc": "/images/zocdoc.png",
        "cars": "/images/cars-icon.png",
        "cargurus": "/images/cargurus-icon.png",
        "edmunds": "/images/edmunds-icon.png",
        "dealerrater": "/images/dealerrater-icon.png",
        "talabat": "/images/talabat-icon.png",
        "reserveout": "/images/reserveout-icon.png",
        "homeadvisor": "/images/homeadvisor-icon.png",
        "trustpilot": "/images/trustpilot.png",
        "trustradius": "/images/trustradius-icon.png",
        "reviewshaketrustpilot": "/images/trustpilot-index.png",
	    "capterra": "/images/capterra-icon.png",
        "gtwo": "/images/gtwo-icon.png",
        "airbnb": "/images/airbnb-icon.png",
        "avvo": "/images/avvo-icon.png",
        "houzz": "/images/houzz-icon.png",
        "homestars": "/images/homestarts-icon.png",
        "rent": "/images/rent-icon.png",
        "highya": "/images/highya-icon.png",
        "crowd": "/images/crowd-icon.png",
        "angieslist": "/images/angieslist-icon.png",
        "reviewshakefacebook": "/images/reviewshakefacebook-icon.png",
        "betterbusinessbureau": "/images/BBB-icon.png",
        "playstore":'/images/playstore-icon.png',
        "appleappstore" :'/images/appstore-icon.png',
        "applePlaces" :'/images/applemaps-icon.png',
        "applebusinessconnect":'/images/applemaps-icon.png',
        "trustedshops":'/images/trustedshops-icon.png',
        "cardealer":'/images/cardealer-icon.png',
        "googlepublic":'/images/googlepublic.png ',
        "ubereats":'/images/ubereats-icon.png',
        'doordash':'/images/doordash-icon.png',
        'grubhub':'/images/grubhub-icon.png'

    },
    sourceNames: {
        "yelp": "Yelp",
        "booking": "Booking",
        "reviewshakebooking": "Booking Index",
        "reviewshakefacebook": "Facebook Index",
        "betterbusinessbureau": "BBB",
        "cellarpass": "Cellarpass",
        "google": "Google Business Profile",
        "facebook": "Facebook",
        "tripadvisor": "Tripadvisor",
        "lawyers": "Lawyers",
        "ratemds": "Ratemds",
        "vitals": "Vitals",
        "healthgrades": "Healthgrades",
        "productreview": "Product Review",
        "apartments": "Apartments",
        "zomato": "Zomato",
        "glassdoor": "Glassdoor",
        "hostellingscotland": "Hostelling Scotland",
        "hostelworld": "Hostel World",
        "expedia": "Expedia",
        "indeed": "Indeed",
        "opentable": "Open Table",
        "zillow": "Zillow",
        "hotels": "Hotels",
        "foursquare": "Foursquare",
        "reviewshakegmb": "GBP Public",
        "googleplaystore": "Google Play Index",
        "appstore": "App Store Index",
        "appleappstore":"App Store",
        // "applemaps":"Apple Places",
        "applebusinessconnect":"Apple Maps",
        "zocdoc": "Zocdoc",
        "cars": "Cars",
        "cargurus": "CarGurus",
        "edmunds": "Edmunds",
        "dealerrater": "DealerRater",
        "talabat": "Talabat",
        "reserveout": "Reserveout",
        "homeadvisor": "HomeAdvisor",
        "trustpilot": "Trustpilot",
        "trustradius": "Trust Radius",
        "reviewshaketrustpilot": "Trustpilot Index",
	    "capterra": "Capterra",
        "gtwo": "Gtwo",
        "airbnb": "Airbnb",
        "avvo": "Avvo",
        "houzz": "Houzz",
        "homestars": "HomeStars",
        "rent": "Rent",
        "highya": "Highya",
        "crowd": "Crowd",
        "angieslist": "Angieslist",
        "playstore":"Google Play",
        "trustedshops":"TrustedShops",
        "cardealer":"Car Dealer Reviews",
        'googlepublic':'Google Public',
        'ubereats': 'Uber Eats',
        'grubhub':'Grubhub',
        'doordash': 'Doordash',
        
    },
    sourceFilterCardNames: {
        "yelp": "Yelp",
        "booking": "Booking",
        "reviewshakebooking": "Booking Index",
        "reviewshakefacebook": "Facebook Index",
        "betterbusinessbureau": "BBB",
        "cellarpass": "Cellarpass",
        "google": "Google",
        "facebook": "Facebook",
        "tripadvisor": "Tripadvisor",
        "lawyers": "Lawyers",
        "ratemds": "Ratemds",
        "vitals": "Vitals",
        "healthgrades": "Healthgrades",
        "productreview": "Product Review",
        "apartments": "Apartments",
        "zomato": "Zomato",
        "glassdoor": "Glassdoor",
        "hostellingscotland": "Hostelling Scotland",
        "hostelworld": "Hostel World",
        "expedia": "Expedia",
        "indeed": "Indeed",
        "opentable": "Open Table",
        "zillow": "Zillow",
        "hotels": "Hotels",
        "foursquare": "Foursquare",
        "reviewshakegmb": "GBP Public",
        "googleplaystore": "Google Play Index",
        "appstore": "App Store Index",
        "appleappstore":"App Store",
        // "applemaps":"Apple Places",
        "applebusinessconnect":"Apple Maps",
        "zocdoc": "Zocdoc",
        "cars": "Cars",
        "cargurus": "CarGurus",
        "edmunds": "Edmunds",
        "dealerrater": "DealerRater",
        "talabat": "Talabat",
        "reserveout": "Reserveout",
        "homeadvisor": "HomeAdvisor",
        "trustpilot": "Trustpilot",
        "trustradius": "Trust Radius",
        "reviewshaketrustpilot": "Trustpilot Index",
	    "capterra": "Capterra",
        "gtwo": "Gtwo",
        "airbnb": "Airbnb",
        "avvo": "Avvo",
        "houzz": "Houzz",
        "homestars": "HomeStars",
        "rent": "Rent",
        "highya": "Highya",
        "crowd": "Crowd",
        "angieslist": "Angieslist",
        "playstore":"Google Play",
        "trustedshops":"TrustedShops",
        "cardealer":"Car Dealer",
        'googlepublic':'Google Public',
        'ubereats': 'Uber Eats',
        'grubhub':'Grubhub',
        'doordash': 'Doordash',
        
    },
    SYSTEM_COLOR_MAP: [{ source: "google", color: "#4885ED" },
    { source: "facebook", color: "#3B5998" },
    { source: "tripadvisor", color: "#00AF87" },
    { source: "yelp", color: "#F15C4F" },
    { source: "booking", color: "#003580" },
    { source: "reviewshakebooking", color: "#003580" },
    { source: "cellarpass", color: "#92A2A8" },
    { source: "lawyers", color: "#00B0F2" },
    { source: "ratemds", color: "#529A9E" },
    { source: "vitals", color: "#673Ab7" },
    { source: "healthgrades", color: "#0202EA" },
    { source: "productreview", color: "#ef6023" },
    { source: "apartments", color: "#88DA64" },
    { source: "zomato", color: "#CB202D" },
    { source: "zocdoc", color: "#C382FF" },
    { source: "trustpilot", color: "#F37324" },
    { source: "glassdoor", color: "#51ab42" },
    { source: "hostellingscotland", color: "#005CB9" },
    { source: "hostelworld", color: "#ee734c" },
    { source: "expedia", color: "#FFC717" },
    { source: "indeed", color: "#2162f4" },
    { source: "opentable", color: "#DA3743" },
    { source: "zillow", color: "#1277E1" },
    { source: "hotels", color: "#EC2C45" },
    { source: "foursquare", color: "#F94877" },
    { source: "reviewshakegmb", color: "#ACB9CF" },
    { source: "googleplaystore", color: "#227491" },
    { source: "appstore", color: "#555555" },
    { source: "appleappstore", color: "#000000" },
    // { source: "applemaps", color: "#2ec219" },
    { source: "applebusinessconnect", color: "#2ec219" },
    { source: "cars", color: "#532380" },
    { source: "cargurus", color: "#ED3341" },
    { source: "dealerrater", color: "#FA7412"},
    { source: "edmunds", color: "#565658"},
    { source: "talabat", color: "#FF5A00"},
    { source: "reserveout", color: "#EB1C22"},
    { source: "homeadvisor", color: "#F8901F"},
    { source: "trustradius", color: "#116BF2"},
    { source: "capterra", color: "#68C4E9"},
    { source: "gtwo", color: "#EF492D"},
    { source: "airbnb", color: "#EB5A62"},
    {source:'reviewshaketrustpilot', color:'#67f2ca'},
    { source: "reviewshakefacebook", color: "#3B5998" },
    { source: "betterbusinessbureau", color: "#006187" },
    {source: "playstore", color: "#3BCCFF"},
    {source:"trustedshops", color:"#ffdd39"},
    {source:"cardealer", color:"#7665ea"},
    {source:"ubereats", color:"#01C268"},
    {source:"doordash", color:"#EB1700"},
    {source:"grubhub", color:"#FF8000"},
    ],
    integrationSources: ["google", "facebook", "tripadvisor", "reviewshakebooking",
        "yelp", "booking", "cellarpass", "lawyers", "ratemds", "vitals",
        "healthgrades", "productreview", "apartments", "zomato", "zocdoc", "trustpilot", "glassdoor", "hostellingscotland", "hostelworld", "expedia",
        "indeed", "opentable", "zillow", "hotels", "foursquare", "reviewshakegmb", "googleplaystore", "appstore", "cars", "cargurus","dealerrater","edmunds","reserveout","talabat","homeadvisor","trustradius",'capterra',"gtwo","airbnb","reviewshakefacebook","cardealer","reviewshaketrustpilot",
        "betterbusinessbureau","playstore", "appleappstore","applemaps","trustedshops","applebusinessconnect","ubereats", "doordash","grubhub"
    ]
}